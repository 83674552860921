import "core-js/modules/es.array.push.js";
import draggable from 'vuedraggable';
import contextMenuService from '@/services/settings/contextMenuService.js';
import ErrorDialog from '../core/ErrorDialog.vue';
export default {
  components: {
    draggable,
    ErrorDialog
  },
  data: () => ({
    contextMenuDragAndDrop: false,
    selected: [],
    toSelect: [],
    diff: [],
    dragging: false,
    errorDialog: false
  }),
  methods: {
    open(selected, toSelect) {
      selected.forEach(element => {
        this.selected.push(contextMenuService.toGermanName(element));
      });
      this.toSelect = toSelect;
      const mappedSelected = this.selected.map(name => {
        const {
          id
        } = this.toSelect.find(item => item.name === name) || {
          id: null
        };
        return {
          name,
          id
        };
      });
      this.selected = mappedSelected;
      this.diff = this.toSelect.filter(item => !this.selected.some(otherItem => otherItem.name === item.name));
      this.contextMenuDragAndDrop = true;
    },
    save() {
      this.$emit('saveContextMenu');
      this.contextMenuDragAndDrop = false;
    },
    log(event) {
      if (event.removed !== undefined && this.selected.length === 1) {
        this.errorDialog = true;
        this.selected.push(event.removed.element);
        this.diff.forEach((item, index) => {
          if (item.name == event.removed.element.name) {
            this.diff.splice(index, 1);
          }
        });
      }
    }
  }
};