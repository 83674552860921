import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "max-width": "600"
    },
    model: {
      value: _vm.contextMenuDragAndDrop,
      callback: function ($$v) {
        _vm.contextMenuDragAndDrop = $$v;
      },
      expression: "contextMenuDragAndDrop"
    }
  }, [_c(VCard, [_c(VToolbar, {
    attrs: {
      "color": "primary",
      "dark": "",
      "elevation": "0"
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.contextMenuDragAndDrop = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v(" Kontextmenü bearbeiten ")])], 1), _c(VSimpleTable, [_c('tbody', [_c('tr', [_c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Anzeigereihenfolge")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Mögliche Elemente")])]), _c('tr', [_c('td', [_c('draggable', {
    staticClass: "list-group",
    attrs: {
      "list": _vm.selected,
      "group": "contextMenu",
      "ghost-class": "ghost"
    },
    on: {
      "start": function ($event) {
        _vm.dragging = true;
      },
      "end": function ($event) {
        _vm.dragging = false;
      },
      "change": _vm.log
    }
  }, [_c('transition-group', _vm._l(_vm.selected, function (element) {
    return _c(VRow, {
      key: element.id,
      staticClass: "ma-2 pa-2 rounded-1 draggableItem",
      staticStyle: {
        "border": "1px solid #cfcfcf",
        "border-radius": "5px"
      }
    }, [_c('h4', {
      staticClass: "font-weight-regular"
    }, [_vm._v(" " + _vm._s(element.name) + " ")]), _c(VSpacer), _c(VIcon, {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-drag-horizontal-variant")])], 1);
  }), 1)], 1)], 1), _c('td', [_c('draggable', {
    staticClass: "list-group",
    attrs: {
      "list": _vm.diff,
      "group": "contextMenu",
      "ghost-class": "ghost"
    },
    on: {
      "start": function ($event) {
        _vm.dragging = true;
      },
      "end": function ($event) {
        _vm.dragging = false;
      }
    }
  }, [_c('transition-group', _vm._l(_vm.diff, function (element) {
    return _c(VRow, {
      key: element.id,
      staticClass: "ma-2 pa-2 rounded-1 draggableItem",
      staticStyle: {
        "border": "1px solid #cfcfcf",
        "border-radius": "5px"
      }
    }, [_c('h4', {
      key: element.id,
      staticClass: "font-weight-regular"
    }, [_vm._v(" " + _vm._s(element.name) + " ")]), _c(VSpacer), _c(VIcon, {
      key: element.id,
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-drag-horizontal-variant")])], 1);
  }), 1)], 1)], 1)])])]), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("Speichern")])], 1)], 1), _c('ErrorDialog', {
    attrs: {
      "failMessage": 'Es muss mindestens 1 Element ausgewählt sein!',
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };