import { mapState } from 'vuex';
export default {
  data: () => ({
    headers: [{
      text: 'PID',
      value: 'pid'
    }, {
      text: 'Name',
      value: 'username'
    }, {
      text: 'Kürzel',
      value: 'userId'
    }, {
      text: 'E-Mail',
      value: 'email'
    }, {
      text: 'Hinzufügen',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: '12rem'
    }],
    search: ''
  }),
  computed: {
    ...mapState({
      allUsers: state => state.userSettings.allUsers
    })
  },
  mounted() {
    this.$store.dispatch('loadAllUsers');
  },
  methods: {
    addUser(user) {
      this.allUsers.splice(this.allUsers.indexOf(user), 1);
      this.$emit('addUser', user);
    }
  }
};