import { VTabItem } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VTabItem, {
    key: "user.userId",
    attrs: {
      "transition": "no-transition",
      "reverse-transition": "no-transition"
    }
  }, [_vm.app === 'cal' ? _c('CalUserSettings', {
    attrs: {
      "userId": _vm.user.userId,
      "userLvl": _vm.user.userLvl,
      "settings": _vm.settings
    }
  }) : _vm._e(), _vm.app === 'crm' && false == true ? _c('CrmUserSettings') : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };