import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VRow, {
    staticClass: "d-flex justify-center mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12",
      "lg": "8",
      "xl": "6"
    }
  }, [_c('CalUserSettingsEntry', {
    attrs: {
      "displayText": 'Titelanzeige',
      "detailText": 'Anzeige des Termintitels',
      "typeOfData": 'v-select',
      "value": _vm.eventTitle,
      "items": _vm.eventTitleItems
    },
    on: {
      "changedValue": _vm.changedEventTitle
    }
  }), _c('CalUserSettingsEntry', {
    attrs: {
      "displayText": 'Zoom',
      "detailText": 'Größe der Zeitintervalle',
      "typeOfData": 'v-select',
      "value": _vm.zoom,
      "items": _vm.zoomItems
    },
    on: {
      "changedValue": _vm.changedZoom
    }
  }), _c('CalUserSettingsEntry', {
    attrs: {
      "displayText": 'Standardansicht',
      "detailText": 'Standardansicht beim Aufruf des Kalenders',
      "typeOfData": 'v-select',
      "value": _vm.typeOfCalendarOverview,
      "items": _vm.typeOfCalendarOverviewItems
    },
    on: {
      "changedValue": _vm.changedTypeOfCalendarOverview
    }
  }), _c('CalUserSettingsEntry', {
    attrs: {
      "displayText": 'Kollisionsüberprüfung',
      "detailText": 'Bei Erstellung eines Termins, wird überprüft, ob  eine Adresse, ein Objekt oder eine Ressource bereits belegt ist',
      "typeOfData": 'v-checkbox',
      "value": _vm.collisionDetection
    },
    on: {
      "changedValue": _vm.changedCollisionDetection
    }
  }), _c('CalUserSettingsEntry', {
    attrs: {
      "displayText": 'Kontextmenü',
      "detailText": 'Aufbau der Detailansicht eines Termins',
      "typeOfData": 'contextmenu',
      "value": _vm.contextMenu,
      "items": _vm.contextMenuItems
    },
    on: {
      "changedValue": _vm.changedContextMenu
    }
  }), _c('CalUserSettingsEntry', {
    attrs: {
      "displayText": 'Kontextmenü Anzeigedauer',
      "detailText": 'Anzeigedauer des Kontextmenüs (in Sekunden)',
      "typeOfData": 'number',
      "value": _vm.contextMenuDuration
    },
    on: {
      "changedValue": _vm.changedContextMenuDuration
    }
  }), _vm.userLvl >= 254 ? _c('CalUserSettingsEntry', {
    attrs: {
      "displayText": 'Standardfilter',
      "detailText": 'Terminbereiche und Lokationen, welche angezeigt werden',
      "typeOfData": 'defaultFilter',
      "userId": _vm.userId
    },
    on: {
      "changedValue": _vm.changedDefaultFilter
    }
  }) : _vm._e(), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VContainer, {
    staticClass: "pa-0 d-flex justify-center"
  }, [_c(VBtn, {
    staticClass: "mt-2",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Speichern")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };