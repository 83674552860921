import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mt-4"
  }, [_c(VCardText, [_c(VTabs, {
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTab, {
    key: "masterData"
  }, [_vm._v("Stammdaten")]), _vm.app === 'cal' ? _c(VTab, {
    key: "userSettings"
  }, [_vm._v("Benutzereinstellungen")]) : _vm._e()], 1), _c(VTabsItems, {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTabItem, {
    key: "masterData",
    staticClass: "mt-6 mx-4 mb-4"
  }, [_c('div', {
    staticClass: "component-container"
  }, _vm._l(_vm.masterDataApp.masterDataTabs, function (category, i) {
    return _c('div', {
      key: i
    }, [_c('h2', [_vm._v(" " + _vm._s(i) + " ")]), _c('br'), _c(VExpansionPanels, {
      attrs: {
        "focusable": "",
        "tile": ""
      }
    }, [_vm._l(category, function (type, i) {
      return _c('SettingsPanel', {
        key: i,
        attrs: {
          "text": type.displayName,
          "icon": type.icon,
          "type": type.masterDataCategory
        }
      });
    }), _c(VDivider, {
      staticClass: "m-0"
    })], 2), _c('br')], 1);
  }), 0)]), _c(VTabItem, {
    key: "userSettings",
    staticClass: "mt-6 mx-4 mb-4"
  }, [_c('div', {
    staticClass: "component-container"
  }, [_vm.user.userLvl > 250 ? _c('UserTable', {
    ref: "userTable",
    on: {
      "addUser": _vm.addUser
    }
  }) : _vm._e(), _c(VTabs, {
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.userTab,
      callback: function ($$v) {
        _vm.userTab = $$v;
      },
      expression: "userTab"
    }
  }, [_c(VTab, {
    key: "user.userid"
  }, [_vm._v(_vm._s(_vm.user.username))]), _vm._l(_vm.users, function (user) {
    return _c(VTab, {
      key: user.userid
    }, [_vm._v(_vm._s(user.username) + " "), _c(VBtn, {
      staticClass: "ml-2",
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeUser(user);
        }
      }
    }, [_c(VIcon, {
      attrs: {
        "x-small": ""
      }
    }, [_vm._v("mdi-close")])], 1)], 1);
  })], 2), _c(VTabsItems, {
    model: {
      value: _vm.userTab,
      callback: function ($$v) {
        _vm.userTab = $$v;
      },
      expression: "userTab"
    }
  }, [_c('UserSettingsPanel', {
    attrs: {
      "user": _vm.user,
      "app": _vm.app,
      "settings": _vm.settingsCurrentUser
    }
  }), _vm._l(_vm.users, function (user) {
    return _c('UserSettingsPanel', {
      key: user.userid,
      attrs: {
        "user": user,
        "app": _vm.app,
        "settings": user.settings
      }
    });
  })], 2)], 1)])], 1), _c('Loading', {
    ref: "loading"
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };