import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VExpansionPanel, {
    on: {
      "click": _vm.action
    },
    model: {
      value: _vm.settingsPanel,
      callback: function ($$v) {
        _vm.settingsPanel = $$v;
      },
      expression: "settingsPanel"
    }
  }, [_c(VExpansionPanelHeader, {
    staticClass: "justify-self-start",
    attrs: {
      "disable-icon-rotate": ""
    }
  }, [_c('div', [_c(VIcon, [_vm._v(_vm._s(_vm.icon))]), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.text))])], 1)]), _c(VExpansionPanelContent, [_c('SettingsData', {
    ref: "settingsData",
    attrs: {
      "category": _vm.type
    },
    on: {
      "reload": function ($event) {
        return _vm.action();
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };