import ContextMenuDragAndDrop from './ContextMenuDragAndDrop.vue';
import DefaultFilter from './DefaultFilter.vue';
export default {
  data: () => ({}),
  components: {
    ContextMenuDragAndDrop,
    DefaultFilter
  },
  methods: {
    openContextMenuDragAndDrop() {
      this.$refs.contextMenu.open(this.valueLocal.split(';'), this.items);
    },
    openDefaultFilter() {
      this.$refs.defaultFilter.open(this.userId);
    },
    saveContextMenu() {
      this.valueLocal = this.$refs.contextMenu.selected;
    },
    getActiveScopes() {
      return this.items.scopes;
    },
    getScopes() {
      return this.items;
    }
  },
  mounted() {
    if (this.typeOfData === 'defaultFilter') {
      this.loadDefaultFilter();
    }
  },
  computed: {
    valueLocal: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('changedValue', value);
      }
    }
  },
  props: {
    displayText: {
      type: String,
      required: true
    },
    detailText: {
      type: String,
      required: true
    },
    typeOfData: {
      type: String,
      required: true
    },
    value: {
      required: false
    },
    items: {
      type: Array,
      required: false
    },
    userId: {
      type: String,
      required: false
    }
  }
};