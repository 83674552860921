import axios from 'axios';
export default {
  data: () => ({
    defaultFilter: false,
    scopes: '',
    locations: '',
    activeScopes: '',
    activeLocations: '',
    userId: ''
  }),
  methods: {
    open(userId) {
      this.defaultFilter = true;
      this.userId = userId;
      this.loadDefaultFilter();
    },
    async loadDefaultFilter() {
      axios.get(`/v1/calv2/information/defaultFilter/${this.userId}`).then(data => {
        this.scopes = data.data.scopes;
        this.activeScopes = data.data.scopes.filter(scope => scope.isActive);
        this.locations = data.data.locations;
        this.activeLocations = data.data.locations.filter(location => location.isActive);
      });
    },
    save() {
      var obj = {
        scopes: this.activeScopes,
        locations: this.activeLocations
      };
      axios.post(`/v1/calv2/information/defaultFilter/${this.userId}`, obj).then(() => {
        this.defaultFilter = false;
        this.$toast.success('Standardfilter wurde gespeichert!');
      });
    }
  },
  mounted() {}
};