import { mapState } from 'vuex';
import SettingsData from '@/components/settings/SettingsData.vue';
export default {
  computed: {
    ...mapState({
      masterData: state => state.masterData.masterData
    })
  },
  data: () => ({
    settingsPanel: true,
    headers: [],
    items: []
  }),
  components: {
    SettingsData
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    action() {
      this.$store.dispatch('loadMasterData', this.type).then(() => {
        this.$refs.settingsData.open(this.type, this.masterData.headers, this.masterData.items, this.masterData.clazz);
      });
    }
  },
  watch: {
    settingsPanel: function () {
      console.warn(this.settingsPanel);
    }
  }
};