import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "max-width": "600"
    },
    model: {
      value: _vm.defaultFilter,
      callback: function ($$v) {
        _vm.defaultFilter = $$v;
      },
      expression: "defaultFilter"
    }
  }, [_c(VCard, [_c(VToolbar, {
    attrs: {
      "color": "primary",
      "dark": "",
      "elevation": "0"
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.defaultFilter = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v(" Standardfilter bearbeiten ")])], 1), _c(VCardText, [_c(VSelect, {
    attrs: {
      "label": "Terminlokationen",
      "multiple": "",
      "chips": "",
      "deletable-chips": "",
      "items": _vm.locations,
      "item-value": item => item,
      "item-text": "location.name"
    },
    model: {
      value: _vm.activeLocations,
      callback: function ($$v) {
        _vm.activeLocations = $$v;
      },
      expression: "activeLocations"
    }
  }), _c(VSelect, {
    attrs: {
      "label": "Terminbereiche",
      "multiple": "",
      "chips": "",
      "deletable-chips": "",
      "items": _vm.scopes,
      "item-value": item => item,
      "item-text": "scope.name"
    },
    model: {
      value: _vm.activeScopes,
      callback: function ($$v) {
        _vm.activeScopes = $$v;
      },
      expression: "activeScopes"
    }
  })], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("Speichern")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };