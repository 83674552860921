class UserSetting {
  constructor(description, section, valueName, value) {
    this.description = description
    this.section = section
    this.valueName = valueName
    this.value = value
  }
}

export { UserSetting as UserSettingClass }
