import "core-js/modules/es.array.push.js";
import SettingsPanel from '@/components/settings/SettingsPanel.vue';
import UserSettingsPanel from '@/components/settings/UserSettingsPanel.vue';
import UserTable from '@/components/settings/UserTable.vue';
import { mapState } from 'vuex';
import Loading from '@/components/calendar_v2/Loading.vue';
export default {
  computed: {
    ...mapState({
      masterDataApp: state => state.masterData.masterDataApp,
      user: state => state.user.user,
      settingsCal: state => state.userSettings.settings_cal,
      settingsCurrentUser: state => state.user.settings_cal
    })
  },
  data: () => ({
    tab: null,
    userTab: null,
    table: false,
    headers: [],
    items: [],
    text: '',
    users: []
  }),
  components: {
    SettingsPanel,
    UserSettingsPanel,
    UserTable,
    Loading
  },
  props: {
    app: {
      type: String
    },
    displayName: {
      type: String
    }
  },
  methods: {
    openTable(text) {
      this.$refs.settingsData.open(text);
    },
    addUser(user) {
      var app = this.app;
      this.$refs.loading.open(`Einstellungen werden geladen ...`);
      this.$store.dispatch('loadSettings', {
        user,
        app
      }).then(() => {
        this.$refs.loading.close();
        user.settings = this.settingsCal;
        this.users.push(user);
      });
    },
    removeUser(user) {
      this.users.splice(this.users.indexOf(user), 1);
      this.$refs.userTable.allUsers.push(user);
    }
  },
  mounted() {
    this.$store.dispatch('loadMasterDataApp', this.app);
    this.$store.dispatch('loadAllUsers');
  },
  watch: {
    app: function () {
      this.$store.dispatch('loadMasterDataApp', this.app);
      this.$refs.userTable.search = '';
      this.users = [];
    }
  }
};