import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDataTable, {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.allUsers,
      "items-per-page": 3,
      "search": _vm.search,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VToolbar, {
          attrs: {
            "flat": "",
            "color": "white"
          }
        }, [_c(VTextField, {
          staticClass: "mt-8",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Suche",
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Daten gefunden. ")];
      },
      proxy: true
    }, {
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_c(VBtn, {
          staticClass: "mr-2 col-xl-5",
          attrs: {
            "fab": "",
            "small": "",
            "text": "",
            "elevation": "0"
          },
          on: {
            "click": function ($event) {
              return _vm.addUser(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-plus ")])], 1)];
      }
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };