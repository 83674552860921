import { mapState } from 'vuex';
import CalUserSettings from './CalUserSettings.vue';
export default {
  computed: {
    ...mapState({
      masterData: state => state.masterData.masterData
    })
  },
  components: {
    CalUserSettings
  },
  data: () => ({
    userSettingsPanel: true
  }),
  props: {
    user: {
      type: Object,
      required: true
    },
    app: {
      type: String,
      required: true
    },
    settings: {
      type: Array,
      required: true
    }
  },
  methods: {}
};