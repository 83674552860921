import axios from 'axios'

export default {
  saveMasterData(category, masterData) {
    return axios.put(`v1/settings/master-data/${category}`, masterData)
  },
  deleteMasterData(category, masterData) {
    return axios.delete(`v1/settings/master-data/${category}`, {
      data: masterData
    })
  }
}
